<template>
  <div class="col-12">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المستخدم
                    </th>
                    <th>
                        رقم المشروع
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        المحتوى
                    </th>
                </thead>
                <tbody>
                    <tr v-for="order in orders" :key="order._id">
                        <td>
                            {{ users[order.user_id].name }}
                        </td>
                        <td>
                            {{ order.item_id }}
                        </td>
                        <td>
                            {{ order.date }}
                        </td>
                        <td>
                            {{ order.content }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            orders: [],
            users: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/users', {
            jwt: g.user.jwt
        }).then(function(r){
            r.response.forEach(function(a){
                g.users[a._id] = a
            })
            g.getOrders()
        })
    },
    methods: {
        getOrders(){
            var g = this;
            $.post(api + '/admin/edit-logs', {
                jwt: g.user.jwt
            }).then(function(r){
                g.orders = r.response
            })
        }
    }
}
</script>

<style>

</style>